import Rating from "@/components/data-display/Rating";

function ProductRating({ totalRatingCount, average_product_rating }) {
  return (
    <>
      {totalRatingCount > 0 && (
        <div className="flex items-center space-x-[0.625rem] ">
          <Rating ratingNumber={average_product_rating} />
          <p>{`(${totalRatingCount})`}</p>
        </div>
      )}
    </>
  );
}

export default ProductRating;
