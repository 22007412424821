import { ShoppingCartIcon } from "@/components/data-display/Icon";

function AddToCardButton({ onClick }) {
  return (
    <button
      className="flex items-center justify-center border border-primary text-primary group-hover:bg-primary h-[3rem] mt-[1.125rem] space-x-[0.45rem] group-hover:text-white w-full rounded-[0.625rem]  transition delay-150 duration-300 ease-in-out  cursor-pointer"
      onClick={onClick}
    >
      <ShoppingCartIcon className="text-primary group-hover:text-white text-lg transition delay-150 duration-300 ease-in-out" />
      <p>Add to Cart</p>
    </button>
  );
}

export default AddToCardButton;
