import React from "react";

function DiscountTag({ product }) {
  const calculatePrice = (product) => {
    let first_variation = product.default_variation;

    return [
      first_variation?.price,
      first_variation?.offer_price,
      first_variation?.offer_percent,
    ];
  };

  return (
    <>
      {calculatePrice(product)[1] > 0 && (
        <div className="absolute top-5 left-1 sm:top-5 sm:right-3 cursor-pointer">
          <span className="bg-[#FF912C] text-[0.75rem] rounded-[0.313rem] px-[0.563rem] py-[0.313rem] text-white font-light xl:hidden">
            {calculatePrice(product)[2]} % Off
          </span>
        </div>
      )}
    </>
  );
}

export default DiscountTag;
