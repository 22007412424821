import React from "react";

function ProductPrice({ product, showDiscount }) {
  const calculatePrice = (product) => {
    let first_variation = product.default_variation;

    return [
      first_variation?.price,
      first_variation?.offer_price,
      first_variation?.offer_percent,
    ];
  };

  return (
    <>
      {!showDiscount ? (
        <p className="text-[1.375rem] font-semibold mt-[7px]">
          ৳{product.price}
        </p>
      ) : (
        <p className=" flex items-center flex-wrap">
          <span className="text-[1.375rem] font-semibold mr-[0.625rem]">
            ৳
            {calculatePrice(product)[1]
              ? calculatePrice(product)[1]
              : calculatePrice(product)[0]}
          </span>
          {calculatePrice(product)[1] > 0 && (
            <span className="xl:flex items-center space-x-[0.625rem] flex-wrap  hidden ">
              <span className="text-[#7B838F] text-[1.125rem] line-through ">
                ৳{calculatePrice(product)[0]}
              </span>
              <span className="bg-[#FF912C] text-[0.75rem] rounded-[0.25rem] px-[0.563rem] py-[0.313rem] text-white font-light ">
                {calculatePrice(product)[2]} % Off
              </span>
            </span>
          )}
        </p>
      )}
    </>
  );
}

export default ProductPrice;
