import {
  WishlistActiveIcon,
  WishlistInActiveIcon,
} from "@/components/data-display/Icon";

function WishListButton({ isWishlist, onClick }) {
  return (
    <button
      className="absolute top-[0.75rem] right-[0.625rem] sm:top-[0.75rem] sm:right-[0.688rem] cursor-pointer"
      onClick={onClick}
    >
      {isWishlist && <WishlistActiveIcon />}
      {Boolean(!isWishlist) && (
        <div className="w-[48px] h-[48px] relative md:hidden group-hover:block">
          <WishlistInActiveIcon />
        </div>
      )}
    </button>
  );
}

export default WishListButton;
