import DealsCard from "@/components/product-card/DealsCard";
import HotOffersHeading from "@/components/home/hot-offers-heading";
import Image from "next/image";
import dayjs from "dayjs";

function TodaysDealsSection({ dealProducts }) {
  function calculateDealProduct() {
    let activeProducts = [];
    let inactiveProducts = [];
    dealProducts.forEach((product) => {
      const isDealActive =
        !dayjs().isBefore(dayjs(product?.offer_start_date)) &&
        !dayjs().isAfter(dayjs(product?.offer_end_date));
      if (isDealActive) activeProducts.push(product);
      else inactiveProducts.push(product);
    });

    return [...activeProducts, ...inactiveProducts];
  }

  return (
    <section className="relative bg-[#FFF5F9] py-[70px] px-[.75rem] lg:px-[7.5rem] overflow-clip">
      <div className="pointer-events-none">
        <Image
          src="/assets/deal/top-1.svg"
          width={104}
          height={104}
          className="absolute top-[33px] left-[44px] hidden lg:block"
          alt="Paw"
        />

        <Image
          src="/assets/deal/top-1-mobile.svg"
          width={70}
          height={70}
          className="absolute top-[34px] left-0 lg:hidden"
          alt="Paw"
        />

        <Image
          src="/assets/deal/top-2.svg"
          width={72}
          height={78}
          className="absolute top-0 lg:top-[32px] lg:left-[38%] -right-[27px]"
          alt="Paw"
        />

        <Image
          src="/assets/deal/top-3.svg"
          width={58}
          height={61}
          className="absolute top-[55px] left-[70%] hidden lg:block"
          alt="Paw"
        />

        <Image
          src="/assets/deal/top-4.svg"
          width={58}
          height={61}
          className="absolute top-[40px] right-[65px] hidden lg:block"
          alt="Paw"
        />

        <Image
          src="/assets/deal/bottom-1.svg"
          width={58}
          height={63}
          className="absolute -bottom-[20px] lg:bottom-[24px] left-[21px] lg:left-[38px]"
          alt="Paw"
        />

        <Image
          src="/assets/deal/bottom-2.svg"
          width={58}
          height={63}
          className="absolute bottom-[12px] lg:bottom-[51px] right-0"
          alt="Paw"
        />
      </div>

      <div className="pointer-events-none">
        <Image
          src="/assets/deal/mid-1.svg"
          width={41}
          height={43}
          className="absolute top-[60%] -translate-y-[60%] left-0 lg:hidden "
          alt="Paw"
        />

        <Image
          src="/assets/deal/mid-2.svg"
          width={41}
          height={43}
          className="absolute top-[71%] lg:top-[50%] -translate-y-[71%] lg:-translate-y-[50%]  -right-[8px] lg:right-[37px] -rotate-[320deg] lg:rotate-0"
          alt="Paw"
        />
      </div>

      <div className="mb-[2.5rem] relative">
        <HotOffersHeading
          offerName="Today’s Deals"
          redirectLink="todays-deals"
        />
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-[1.5rem]">
        {calculateDealProduct()
          .slice(0, 4)
          ?.map((product) => (
            <DealsCard product={product} key={product.slug} />
          ))}
      </div>
    </section>
  );
}

export default TodaysDealsSection;
