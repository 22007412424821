import { isAuthenticated } from "@/utils/helpers/auth";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setCurrentProduct } from "@/store/feature/productSlice";
import { addToWishList, removeWishList } from "@/api/wishlist";
import en from "@/localization/en.json";
import classNames from "classnames";
import StockOutBadge from "@/components/data-display/stock-out-badge";
import AddToCartModal from "@/components/modal/AddToCartModal";
import ProductRating from "./ProductRating";
import ProductPrice from "./ProductPrice";
import DiscountTag from "./DiscountTag";
import AddToCardButton from "./AddToCardButton";
import WishListButton from "./WishListButton";
import dynamic from "next/dynamic";
const CountDown = dynamic(() => import("./CountDown"), {
  ssr: false,
});
import dayjs from "dayjs";

function DealsCard({ product: propProductData, showDiscount = true }) {
  const [product, setProductData] = useState(propProductData);

  const addToCardRef = useRef();
  const dispatch = useDispatch();
  const router = useRouter();
  const totalRatingCount = product?.reviews?.total_rating_count;

  const [isWishlist, setIsWishlist] = useState(
    product?.default_variation?.in_wishList
  );

  const handleAddToCart = () => {
    addToCardRef.current.click();
    dispatch(setCurrentProduct(product));
  };

  useEffect(() => {
    setProductData(propProductData);
    setIsWishlist(propProductData?.default_variation.in_wishList);
  }, [propProductData, setIsWishlist]);

  const handleWishlist = async () => {
    if (!(await isAuthenticated(router))) return;

    const productData = {
      product: product?.slug,
    };

    let firstVariation;

    firstVariation = product?.default_variation;

    productData.variation = firstVariation?.variation;

    if (isWishlist) {
      let res = await removeWishList(productData);
      if (res && res?.data) {
        setIsWishlist(false);
      }
    } else if (!isWishlist) {
      let res = await addToWishList(productData);
      if (res && res?.data) {
        setIsWishlist(true);
      }
    }
  };

  const currentTime = dayjs();
  const endTime = dayjs(product.offer_end_date || currentTime);
  const isDealOver = dayjs().isAfter(endTime);
  const totalSeconds = isDealOver ? 0 : endTime.diff(currentTime, "second");

  return (
    <>
      <div className="group w-full rounded-xl relative">
        <div className="border-2 border-transparent group-hover:border-[#EDF3F7] rounded-xl transition delay-150 duration-300 ease-in-out flex flex-col justify-between">
          <Link href={`/product/${product.slug}`}>
            <div
              className={`w-full flex justify-center items-center transition delay-150 duration-300 ease-in-out group-hover:bg-[#FAE8EF] cursor-pointer aspect-square rounded-t-xl`}
            >
              <div className="relative w-full h-full">
                {product?.main_picture && (
                  <Image
                    src={product?.main_picture}
                    alt={product.name}
                    className={classNames(
                      {
                        "opacity-30": product.total_stock === 0,
                      },
                      "rounded-t-xl object-contain"
                    )}
                    fill
                    sizes="(max-width: 768px) 50vw, 33vw"
                  />
                )}
              </div>
              {product.total_stock === 0 && <StockOutBadge />}
            </div>
          </Link>

          <article
            className={classNames(
              {
                "opacity-70": product.total_stock === 0,
              },
              "pb-[1.125rem] px-[0.938rem] group-hover:bg-white rounded-b-xl transition delay-150 duration-300"
            )}
          >
            <div className="space-y-[1rem]">
              <Link href={`/product/${product.slug}`}>
                <p className="text-[1.063rem] text-[#0A101A] mt-[1.125rem] cursor-pointer h-[3.125rem] line-clamp-2">
                  {product.name}
                </p>
              </Link>
              <div className="flex flex-col xl:items-center space-y-2 xl:space-y-0 xl:flex-row">
                <div className="min-h-[37px] lg:min-h-[41px]">
                  {totalSeconds > 0 && (
                    <CountDown totalSeconds={totalSeconds} />
                  )}
                </div>

                <p className="text-primary text-sm font-medium whitespace-nowrap">
                  Limited Stock
                </p>
              </div>

              <div className="h-6">
                <ProductRating
                  totalRatingCount={totalRatingCount}
                  average_product_rating={
                    product?.reviews?.average_product_rating
                  }
                />
              </div>

              <ProductPrice showDiscount={showDiscount} product={product} />
            </div>

            <AddToCardButton onClick={handleAddToCart} />
          </article>
        </div>

        <WishListButton isWishlist={isWishlist} onClick={handleWishlist} />

        <DiscountTag product={product} />

        <label
          htmlFor="add-to-cart-modal"
          className="hidden"
          ref={addToCardRef}
        >
          {en["myCart.add_to_cart"]}
        </label>
      </div>
      <AddToCartModal />
    </>
  );
}

export default DealsCard;
